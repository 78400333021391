.pdfcontainer {
  height: 500px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pdf-container {
  margin: auto;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-page {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  
}

.first-pdf-page {
  margin-bottom: 25px;
}

.pdf-page>canvas {
  max-width: 100%;
  height: auto !important;
}